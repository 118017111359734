import { faCheck, faCookie } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { getCookies, setCookies } from "../../services/auth";
import Button from "../atoms/button";

export default class CookiesPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  componentDidMount() {
    var open = getCookies();
    if (open !== "1") {
      this.toggle();
    }
  }
  toggle = () => this.setState({ open: !this.state.open });

  accept = () => {
    setCookies("1");
    this.toggle();
  };
  render() {
    return (
      <div>
        <Modal
          size="lg"
          className="bottom-modal"
          isOpen={this.state.open}
          toggle={this.toggle}
        >
          <ModalHeader toggle={this.toggle}>
            <FontAwesomeIcon icon={faCookie}></FontAwesomeIcon>&nbsp;We Value
            Your Privacy
          </ModalHeader>
          <ModalBody>
            We utilize local storage to elevate your browsing experience and
            retain your preferences, ensuring you won’t need to re-enter them.
            By continuing to navigate our site, you consent to our use of local
            storage. For further information, please consult our Privacy Policy.
          </ModalBody>
          <ModalFooter>
            <Button style={{ padding: "5px 10px" }} onClick={this.accept}>
              <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>&nbsp; Accept
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
