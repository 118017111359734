import React from "react";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { StaticQuery, graphql } from "gatsby";
import Header from "./molecules/header";
import Footer from "./molecules/footer";
import { Row, Col, Container } from "reactstrap";
import styled from "styled-components";
import "./layout.css";
import Notification from "./atoms/notification";
import image from "../images/background3.jpg";
import imagePortal from "../images/background1.jpg";
import CookiesPopup from "./organisms/cookies";
import Watermark from "../images/EHS Logo lg.png";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;
  font-family: "Roboto", "Open Sans", Arial, Helvetica, sans-serif;
  padding-top: 65px;
  @media only screen and (max-width: 600px) {
    padding-top: 50px;
  }
`;
const Content = styled(Container)`
  max-width: none !important;
  box-shadow: rgb(17 17 26 / 10%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px;
  border: 1px solid #d7d7d7;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-bottom: 25px;
  background-color: white;
  margin: 0;
  border-radius: 0;

  min-height: 100vh;
  box-sizing: border-box;
  font-family: "Roboto", "Open Sans", Arial, Helvetica, sans-serif;
  @media only screen and (max-width: 600px) {
    border-radius: 0;
    overflow: auto;
    margin-bottom: 50px;
  }
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  min-height: 100vh;
  width: 100%;
  pointer-events: none;
  background-image: url(${image});
  background-repeat: no-repeat;
  background-size: cover;
`;

const BackgroundPortal = styled(Background)`
  background-image: url(${imagePortal});
`;

const PageContent = styled(Col)`
  border-top: 10px solid transparent;
  border-bottom: none;
  border-radius: 19px;
  background-color: white;

  @media only screen and (max-width: 600px) {
    padding: 0px 4px;
    margin-top: 5px;
  }
`;

const WaterMark = styled.div`
  position: fixed;
  top: 25%;
  text-align: center;
  opacity: 0.05;
  left: 0;
  width: 100%;
  pointer-events: none;
  z-index: 9;
`;

export default class LayoutFormReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={(data) => (
          <>
            {!this.props.noBackground && <Background></Background>}
            {this.props.noBackground === true && (
              <BackgroundPortal></BackgroundPortal>
            )}
            <WaterMark>
              <img alt="backgroundImg" src={Watermark}></img>
            </WaterMark>
            <Wrapper>
              <Header
                logoLink="/forms/incident-report"
                siteTitle={this.props.headerTitle}
                showNav={true}
              />
              {this.props.noBackground === true && <>{this.props.children}</>}
              {!this.props.noBackground && (
                <Content>
                  <Notification timeToClose={5000}></Notification>
                  <Row>
                    <PageContent>{this.props.children}</PageContent>
                  </Row>
                  <Footer></Footer>
                  <CookiesPopup></CookiesPopup>
                </Content>
              )}
            </Wrapper>
          </>
        )}
      />
    );
  }
}
