import React from "react";
import LayoutFormReport from "../../components/layoutFormReport";
import Seo from "../../components/atoms/seo";
import { graphql } from "gatsby";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import FadeContainer from "../../components/atoms/fadeContainer";

const IFrame = styled.iframe`
  width: 100%;
  min-height: 85vh;
  margin-top: 20px;
`;

class EHSReport extends React.Component {
  render() {
    return (
      <LayoutFormReport
        headerTitle={
          <>
            <span style={{ color: "#707070" }}>Global</span>&nbsp;EHS Metrics
          </>
        }
      >
        <Seo
          title={this.props.t("EHS Incident Portal")}
          keywords={[`PerkinElmer`]}
        />
        <FadeContainer>
          <IFrame
            title="EHS Incident Portal Report"
            src="https://app.powerbi.com/reportEmbed?reportId=edc98094-200e-4bb8-aff0-ee83ccc0fcc9&autoAuth=true&ctid=f9f27055-d80d-47df-abb2-fa20cc88e4e1"
            frameborder="0"
            allowFullScreen="true"
          ></IFrame>
        </FadeContainer>
      </LayoutFormReport>
    );
  }
}
export default withTranslation()(EHSReport);
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "incident-report"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
